import { Route, Routes } from "react-router-dom";
import AppProps from "./AppProps";

const AppRoutes = (props: AppProps) => {
  return (
    <Routes>
      <Route index element={props.mainPage} />
      <Route path="impressum" element={props.impressumPage} />
      <Route path="privacy-policy" element={props.privacyPolicyPage} />
      <Route path="legal-notes" element={props.legalNotesPage} />
      <Route path="feature/">
        <Route path="coaching" element={props.featureCoachingPage} />
        <Route
          path="prozessautomatisierung"
          element={props.featureProzessautomatisierungPage}
        />
        <Route
          path="softwareentwicklung"
          element={props.featureSoftwareentwicklungPage}
        />
        <Route path="training" element={props.featureTrainingPage} />
      </Route>
      <Route path="service/implementierung">
        <Route
          path="implementierung"
          element={props.serviceImplementierungPage}
        />
        <Route
          path="technologieauswahl-und-evaluation"
          element={props.serviceTechnologieauswahlUndEvaluationPage}
        />
        <Route path="training" element={props.serviceTrainingPage} />
        <Route
          path="system-und-software-architektur"
          element={props.serviceSystemUndSoftwareArchitekturPage}
        />
        <Route path="coaching" element={props.serviceCoachingPage} />
        <Route
          path="anforderungsanalyse"
          element={props.serviceAnforderungsanalysePage}
        />
      </Route>
    </Routes>
  );
};
export default AppRoutes;
