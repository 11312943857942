import emailjs from "emailjs-com";
import { useState } from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

const ContactForm = () => {
  // eslint-disable-next-line
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
      .then(
        (result) => {
          clearState();
        },
        (error) => {}
      );
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() =>
        (window.location.href = "mailto:info@thb-it.de?subject=Kontaktanfrage")
      }
    >
      <form name="sentMessage" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                placeholder="Ihr Name"
                onChange={handleChange}
                readOnly={true}
                style={{ cursor: "pointer" }}
              />
              <p className="help-block text-danger"></p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                placeholder="Ihre E-Mail Adresse"
                onChange={handleChange}
                readOnly={true}
                style={{ cursor: "pointer" }}
              />
              <p className="help-block text-danger"></p>
            </div>
          </div>
        </div>
        <div className="form-group">
          <textarea
            name="message"
            id="message"
            className="form-control"
            rows={4}
            placeholder="Ihre Nachricht an uns..."
            onChange={handleChange}
            disabled={true}
            style={{ cursor: "pointer" }}
          ></textarea>
          <p className="help-block text-danger"></p>
        </div>
        <div id="success"></div>
        <button
          type="button"
          className="btn btn-custom btn-lg"
          style={{ cursor: "pointer" }}
        >
          Nachricht senden
        </button>
      </form>
    </div>
  );
};
export default ContactForm;
