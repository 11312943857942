import { Box, Grid, Skeleton } from "@mui/material";
import { Service } from "./Service/Service";
import ServiceProps from "./Service/ServiceProps";
import "./services.css";
import ServicesProps from "./ServicesProps";

export const Services = (props: ServicesProps) => {
  function renderSkeleton() {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  function renderServices() {
    return props.services!.map((serviceProps: ServiceProps, i: number) => (
      <Grid item xs={12} md={4} key={serviceProps.slug}>
        <Service {...serviceProps} />
      </Grid>
    ));
  }

  return (
    <Box id="services" className="section text-center">
      <div className="section-title">
        <h2>Unsere Dienstleistungen</h2>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        {props.services ? renderServices() : renderSkeleton()}
      </Grid>
    </Box>
  );
};
