import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import CoachingPageProps from "src/ui/Pages/Features/Coaching/CoachingPageProps";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";

const FEATURE_COACHING_CONTENT_IDENTIFIER = "feature-coaching";

const CoachingPageInteractions = {
  loadCoachingPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): CoachingPageProps => {
    return {
      loadCoachingContentFunction: () =>
        loadCoachingContent(repository, cacheEntityResolver),
    };
  },
};

export default CoachingPageInteractions;

async function loadCoachingContent(
  repository: typeof GhostRepository,
  cacheEntityResolver: CacheEntityResolver
): Promise<HtmlPanelProps> {
  const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
    () =>
      repository.findSinglePostOrPageByIdentifierAsHtml(
        FEATURE_COACHING_CONTENT_IDENTIFIER
      ),
    FEATURE_COACHING_CONTENT_IDENTIFIER
  );

  return HtmlPanelPropsMapper.map(postsOrPage);
}
