import { PostOrPage } from "@tryghost/content-api";
import JsonParser from "src/infrastructure/utils/JsonParser/JsonParser";
import ProjectsProps from "src/ui/Pages/Main/Projects/ProjectsProps";

const ProjectsPropsMapper = {
  map: (postsOrPages: PostOrPage[]): ProjectsProps => {
    return {
      projects: postsOrPages.map((postOrPage) => {
        return {
          ...JsonParser.parseJson(postOrPage.plaintext!),
          imageUrl: postOrPage.feature_image!,
        };
      }),
    };
  },
};
export default ProjectsPropsMapper;
