import { Box, Link, Typography } from "@mui/material";
import Routes from "../Routes/Routes";

import "./navigationMenu.css";

interface NavigationMenuProps {
  logoUrl: string;
  onMenuSelect?: () => void;
}

const NavigationMenu = (props: NavigationMenuProps) => {
  return (
    <Box className="drawer" role="presentation">
      <div className="logo-img-container-mobile">
        <img src={props.logoUrl} id="logo-img" alt="" />
      </div>
      <div className="pages-list-drawer">
        {Routes.map((identifier) => (
          <Link
            noWrap={true}
            underline="none"
            href={identifier.link}
            className="page-scroll"
            onClick={props.onMenuSelect}
          >
            <div className="pages-typography">
              <Typography className="navigation-menu-typography">
                {identifier.name}
              </Typography>
            </div>
          </Link>
        ))}
      </div>
      <div className="design-element-right"></div>
    </Box>
  );
};

export default NavigationMenu;
