import BackgroundImageReplacer from "src/ui/utils/BackgroundImageReplacer";
import StringUtils from "src/ui/utils/StringUtils/StringUtils";

const HeaderController = {
  setBackgroundImage: (
    ref: React.RefObject<HTMLDivElement>,
    imageUrl?: string
  ): void => {
    BackgroundImageReplacer.setBackgroundImage(
      ref,
      "header-background",
      imageUrl
    );
  },

  toPhoneUrl: (phoneNumber: string): string => {
    return "tel:" + StringUtils.removeNewlineAndSpace(phoneNumber);
  },
  toMailUrl: (mailAddress: string): string => {
    return "mailto:" + StringUtils.removeNewlineAndSpace(mailAddress);
  },
};
export default HeaderController;
