import HtmlReplacer from "src/ui/utils/HtmlReplacer";

const ServiceController = {
  insertContent: (divId: string, html: string) => {
    HtmlReplacer.replaceHtml(html, divId);
  },
  insertIcon: (divId: string, html: string) => {
    HtmlReplacer.replaceHtml(html, divId);
  },
};
export default ServiceController;
