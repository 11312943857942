import BackgroundImageReplacer from "src/ui/utils/BackgroundImageReplacer";

const ProjectsController = {
  setBackgroundImage: (
    ref: React.RefObject<HTMLDivElement>,
    imageUrl?: string
  ): void => {
    BackgroundImageReplacer.setBackgroundImage(
      ref,
      "project-background",
      imageUrl
    );
  },
};
export default ProjectsController;
