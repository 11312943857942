import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import TechnologieauswahlUndEvaluationPageProps from "src/ui/Pages/Services/TechnologieauswahlUndEvaluation/TechnologieauswahlUndEvaluationPageProps";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";

const SERVICE_TECHNOLOGIEAUSWAHL_UND_EVALUATION_CONTENT_IDENTIFIER =
  "service-technologieauswahl-und-evaluation";

const TechnologieauswahlUndEvaluationPageInteractions = {
  loadTechnologieauswahlUndEvaluationPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): TechnologieauswahlUndEvaluationPageProps => {
    return {
      loadTechnologieauswahlUndEvaluationContentFunction: () =>
        loadTechnologieauswahlUndEvaluationContent(
          repository,
          cacheEntityResolver
        ),
    };
  },
};

export default TechnologieauswahlUndEvaluationPageInteractions;

async function loadTechnologieauswahlUndEvaluationContent(
  repository: typeof GhostRepository,
  cacheEntityResolver: CacheEntityResolver
): Promise<HtmlPanelProps> {
  const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
    () =>
      repository.findSinglePostOrPageByIdentifierAsHtml(
        SERVICE_TECHNOLOGIEAUSWAHL_UND_EVALUATION_CONTENT_IDENTIFIER
      ),
    SERVICE_TECHNOLOGIEAUSWAHL_UND_EVALUATION_CONTENT_IDENTIFIER
  );

  return HtmlPanelPropsMapper.map(postsOrPage);
}
