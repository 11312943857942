const AppController = {
  setDocumentTitle(title: string): void {
    document.title = title;
  },

  setIcon(iconUrl: string): void {
    let link = document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = iconUrl;
    document.head.appendChild(link);
  },
};
export default AppController;
