import { Grid } from "@mui/material";
import "./social-bar.css";
import SocialBarController from "./SocialBarController";
import SocialBarProps from "./SocialBarProps";

const SocialBar = (props: SocialBarProps) => {
  function renderIcon(channel?: string, fontAwesomeIconClassName?: string) {
    return (
      channel && (
        <Grid item>
          <a href={channel} target={"_blank"} rel="noreferrer">
            <i className={"fa " + fontAwesomeIconClassName}></i>
          </a>
        </Grid>
      )
    );
  }

  return (
    <>
      {SocialBarController.doesAnySocialAccountExist(props) && (
        <Grid
          container
          className="social"
          justifyContent={"center"}
          spacing={3}
        >
          {renderIcon(props.xing, "fa-xing")}
          {renderIcon(props.linkedin, "fa-linkedin")}
          {renderIcon(props.facebook, "fa-facebook")}
          {renderIcon(props.twitter, "fa-twitter")}
          {renderIcon(props.youtube, "fa-youtube")}
        </Grid>
      )}
    </>
  );
};
export default SocialBar;
