import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import GhostRepository from "../infrastructure/ghost/GhostRepository";
import GlobalAppData from "../ui/app/GlobalAppData";
import AppInteractions from "./app/AppInteractions";
import FeatureCoachingPageInteractions from "./Features/CoachingPageInteractions";
import ProzessautomatisierungPageInteractions from "./Features/ProzessautomatisierungPageInteractions";
import SoftwareentwicklungPageInteractions from "./Features/SoftwareentwicklungPageInteractions";
import FeatureTrainingPageInteractions from "./Features/TrainingPageInteractions";
import ImpressumPageInteractions from "./legal/ImpressumPageInteractions";
import LegalNotesPageInteractions from "./legal/LegalNotesPageInteractions";
import MainPageInteractions from "./MainPage/MainPageInteractions";
import PrivacyPolicyPageInteractions from "./legal/PrivacyPolicyPageInteractions";
import AnforderungsanalysePageInteractions from "./Services/AnforderungsanalysePageInteractions";
import ServiceCoachingPageInteractions from "./Services/CoachingPageInteractions";
import ImplementierungPageInteractions from "./Services/ImplementierungPageInteractions";
import SystemUndSoftwareArchitekturPageInteractions from "./Services/SystemUndSoftwareArchitekturPageInteractions";
import TechnologieauswahlUndEvaluationPageInteractions from "./Services/TechnologieauswahlUndEvaluationPageInteractions";
import ServiceTrainingPageInteractions from "./Services/TrainingPageInteractions";

const Interactions = {
  loadApplication: async (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<GlobalAppData> =>
    AppInteractions.loadApp(repository, cacheEntityResolver),

  loadMainPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) => MainPageInteractions.loadMainPage(repository, cacheEntityResolver),

  loadImpressumPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    ImpressumPageInteractions.loadImpressumPage(
      repository,
      cacheEntityResolver
    ),

  loadLegalNotesPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    LegalNotesPageInteractions.loadLegalNotesPage(
      repository,
      cacheEntityResolver
    ),

  loadPrivacyPolicyPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    PrivacyPolicyPageInteractions.loadPrivacyPolicyPage(
      repository,
      cacheEntityResolver
    ),

  loadFeatureCoachingPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    FeatureCoachingPageInteractions.loadCoachingPage(
      repository,
      cacheEntityResolver
    ),

  loadFeatureProzessautomatisierungPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    ProzessautomatisierungPageInteractions.loadProzessautomatisierungPage(
      repository,
      cacheEntityResolver
    ),

  loadFeatureSoftwareentwicklungPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    SoftwareentwicklungPageInteractions.loadSoftwareentwicklungPage(
      repository,
      cacheEntityResolver
    ),

  loadFeatureTrainingPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    FeatureTrainingPageInteractions.loadTrainingPage(
      repository,
      cacheEntityResolver
    ),

  loadServiceImplementierungPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    ImplementierungPageInteractions.loadImplementierungPage(
      repository,
      cacheEntityResolver
    ),

  loadServiceTechnologieauswahlUndEvaluationPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    TechnologieauswahlUndEvaluationPageInteractions.loadTechnologieauswahlUndEvaluationPage(
      repository,
      cacheEntityResolver
    ),

  loadServiceTrainingPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    ServiceTrainingPageInteractions.loadTrainingPage(
      repository,
      cacheEntityResolver
    ),

  loadServiceSystemUndSoftwareArchitekturPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    SystemUndSoftwareArchitekturPageInteractions.loadSystemUndSoftwareArchitekturPage(
      repository,
      cacheEntityResolver
    ),

  loadServiceCoachingPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    ServiceCoachingPageInteractions.loadCoachingPage(
      repository,
      cacheEntityResolver
    ),

  loadServiceAnforderungsanalysePage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ) =>
    AnforderungsanalysePageInteractions.loadAnforderungsanalysePage(
      repository,
      cacheEntityResolver
    ),
};
export default Interactions;
