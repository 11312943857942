import { useEffect, useState } from "react";
import HtmlPanel from "src/ui/components/HtmlPanel/HtmlPanel";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import PrivacyPolicyPageProps from "./PrivacyPolicyPageProps";

const PrivacyPolicyPage = (props: PrivacyPolicyPageProps) => {
  const [privacyPolicyContent, setPrivacyPolicyContent] =
    useState<HtmlPanelProps>();

  useEffect(() => {
    props.loadPrivacyPolicyContentFunction().then(setPrivacyPolicyContent);
  }, [props]);

  return <HtmlPanel id="privacy-policy" {...privacyPolicyContent} />;
};

export default PrivacyPolicyPage;
