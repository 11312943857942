import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import ImplementierungPageProps from "src/ui/Pages/Services/Implementierung/ImplementierungPageProps";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";

const SERVICE_IMPLEMENTIERUNG_CONTENT_IDENTIFIER = "service-implementierung";

const ImplementierungPageInteractions = {
  loadImplementierungPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): ImplementierungPageProps => {
    return {
      loadImplementierungContentFunction: () =>
        loadImplementierungContent(repository, cacheEntityResolver),
    };
  },
};

export default ImplementierungPageInteractions;

async function loadImplementierungContent(
  repository: typeof GhostRepository,
  cacheEntityResolver: CacheEntityResolver
): Promise<HtmlPanelProps> {
  const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
    () =>
      repository.findSinglePostOrPageByIdentifierAsHtml(
        SERVICE_IMPLEMENTIERUNG_CONTENT_IDENTIFIER
      ),
    SERVICE_IMPLEMENTIERUNG_CONTENT_IDENTIFIER
  );

  return HtmlPanelPropsMapper.map(postsOrPage);
}
