import { Box, Stack } from "@mui/material";
import techstackImage from "./techstack.png";

const Techstack = () => {
  return (
    <Box id="techstack" className="section text-center">
      <div className="section-title">
        <h2>Technologien</h2>
      </div>
      <Stack spacing={2} direction="column" justifyContent="center">
        <p>
          Dies ist eine Auswahl der von uns verwendeten Sprachen, Technologien
          und Bibliotheken.
        </p>
        <img src={techstackImage} width={"100%"} alt="Techstack" />
      </Stack>
    </Box>
  );
};
export default Techstack;
