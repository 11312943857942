import SocialBarProps from "src/ui/Pages/Main/Contact/components/SocialBar/SocialBarProps";
import StringUtils from "src/ui/utils/StringUtils/StringUtils";

const SocialBarController = {
  doesAnySocialAccountExist: (
    socialBarProps: SocialBarProps | undefined
  ): boolean => {
    if (socialBarProps) {
      return !(
        StringUtils.isEmpty(socialBarProps.facebook) &&
        StringUtils.isEmpty(socialBarProps.linkedin) &&
        StringUtils.isEmpty(socialBarProps.twitter) &&
        StringUtils.isEmpty(socialBarProps.xing) &&
        StringUtils.isEmpty(socialBarProps.youtube)
      );
    }
    return false;
  },
};
export default SocialBarController;
