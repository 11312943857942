import {
  CircularProgress,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import SmoothScroll from "smooth-scroll";
import ScrollButton from "../components/floatingActionButton/ScrollButton";
import AppTheme from "../theme/AppTheme";
import DataReceivedHandlerRegistration from "../utils/DataReceivedHandlerRegistration";
import "./App.css";
import AppController from "./AppController";
import AppProps from "./AppProps";
import AppRoutes from "./AppRoutes";
import GlobalAppData from "./GlobalAppData";
import Footer from "./Layout/Footer/Footer";
import Header from "./Layout/Header/Header";
import Navigation from "./Layout/Navigation/Navigation";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = (props: AppProps) => {
  const [data, setData] = useState<GlobalAppData>();

  useEffect(() => {
    DataReceivedHandlerRegistration.registerDataReceivedHandler(
      props.loadAppDataFunction(),
      (data: GlobalAppData) => {
        setData(data);
        AppController.setDocumentTitle(data.title);
        AppController.setIcon(data.iconUrl);
      }
    );
  }, [props]);

  function renderLoadingIndicator() {
    return (
      <CircularProgress
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );
  }

  return (
    <>
      {!data && renderLoadingIndicator()}
      {data && (
        <BrowserRouter>
          <ScrollButton />
          <ThemeProvider theme={AppTheme}>
            <StyledEngineProvider injectFirst>
              <Navigation {...data.navigation} />
              <Header {...data.header} />
              <AppRoutes {...props} />
              <Footer {...data.footer} />
            </StyledEngineProvider>
          </ThemeProvider>
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
