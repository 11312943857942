import PageSettings from "src/integration/app/PageSettings";
import Company from "src/integration/common/Company/Company";
import HeaderProps from "src/ui/app/Layout/Header/HeaderProps";

const HeaderPropsMapper = {
  map: (company: Company, pageSettings: PageSettings): HeaderProps => {
    return {
      ...company,
      ...pageSettings,
    };
  },
};

export default HeaderPropsMapper;
