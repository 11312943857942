import { Box, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import "./team.css";
import TeamMember from "./TeamMember/TeamMember";
import TeamMemberProps from "./TeamMember/TeamMemberProps";
import TeamProps from "./TeamProps";
import TeamController from "./TeamController";
import TeamMemberGridProperties from "./TeamMemberGridProperties";

export const Team = (props: TeamProps) => {

  function renderSkeleton() {
    return (
      <>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton />
        </Grid>

      </>
    );
  }
  
  function renderTeamMembers() {
    const calculatedGridProperties: TeamMemberGridProperties = TeamController.calculateGridProperties(props.members!);
    return props.members!.map((member: TeamMemberProps, index: number) => (
      <Grid item xs={calculatedGridProperties.xs} md={calculatedGridProperties.md} lg={calculatedGridProperties.lg} key={`${index}`}>
        <TeamMember {...member} />
      </Grid>
    ));
  }

  return (
    <Box id="team" className="section text-center">
      <div className="section-title">
        <h2>Das Team</h2>
      </div>
      <Grid container spacing={3} justifyContent={"flex-start"}>
        {props.members ? renderTeamMembers() : renderSkeleton()}
      </Grid>
    </Box>
  );
};
