import { Box, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Feature } from "./feature/Feature";
import "./features.css";
import FeaturesProps from "./FeaturesProps";

export const Features = (props: FeaturesProps) => {
  function renderSkeletons() {
    return (
      <>
        <Grid item xs={12} sm={4} md={4}>
          <Skeleton />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Skeleton />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Skeleton />
        </Grid>
      </>
    );
  }

  function renderFeatures() {
    return props.features!.map((feature, index: number) => (
      <Grid item xs={12} md={4} key={feature.slug}>
        <Feature
          key={feature.slug}
          {...feature}
          className={"feature-" + ((index % 4) + 1)}
        />
      </Grid>
    ));
  }

  return (
    <Box id="features" className="section">
      <div className="section-title">
        <h2>Unsere Kompetenzen</h2>
      </div>
      <Grid
        container
        direction={"row"}
        alignContent={"space-between"}
        justifyContent="space-evenly"
        spacing={2}
      >
        {props.features ? renderFeatures() : renderSkeletons()}
      </Grid>
    </Box>
  );
};
