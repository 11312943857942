import Company from "src/integration/common/Company/Company";
import FeaturedPageProps from "src/ui/app/Layout/Footer/FeaturedPage/FeaturedPageProps";
import FooterProps from "src/ui/app/Layout/Footer/FooterProps";

const FooterPropsMapper = {
  map: (
    company: Company,
    featuredPageProps: FeaturedPageProps
  ): FooterProps => {
    return {
      featuredPage: featuredPageProps,
      ...company,
    };
  },
};

export default FooterPropsMapper;
