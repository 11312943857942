interface RouteIdentifier {
  name: string;
  link: string;
}

const Routes: Array<RouteIdentifier> = [
  {
    name: "Kompetenzen",
    link: "#features",
  },
  {
    name: "Über uns",
    link: "#about",
  },
  {
    name: "Dienstleistungen",
    link: "#services",
  },
  {
    name: "Technologiestack",
    link: "#techstack",
  },
  {
    name: "Projekte",
    link: "#projects",
  },
  {
    name: "Kundenstimmen",
    link: "#testimonials-container",
  },
  {
    name: "Team",
    link: "#team",
  },
  {
    name: "Kontakt",
    link: "#contact",
  },
];

export default Routes;
