import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import NavigationProps from "./NavigationProps";
import debounce from "./components/Debounce";

import { Link } from "@mui/material";
import { useEffect, useState } from "react";
import Routes from "./Routes/Routes";
import NavigationMenu from "./components/NavigationMenu";
import "./navigation.css";

function Navigation(props: NavigationProps) {
  const [isOpen, setOpen] = useState(false);
  //const anchorDirection = {left: false}

  /* get information about ScrollPosition and Navbar Visibility
     for NavbarVisibleOnScroll component */
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0);
  const [navbarVisible, setNavbarVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPosition = window.scrollY;

    setNavbarVisible(
      (previousScrollPosition > currentScrollPosition &&
        previousScrollPosition - currentScrollPosition > 70) ||
        currentScrollPosition < 10
    );

    setPreviousScrollPosition(currentScrollPosition);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [previousScrollPosition, navbarVisible, handleScroll]);

  return (
    <>
      <Drawer
        className="drawer-page"
        anchor="left"
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <NavigationMenu
          logoUrl={props.logoUrl}
          onMenuSelect={() => setOpen(false)}
        />
      </Drawer>
      <AppBar
        className="app-bar"
        style={{
          top: navbarVisible ? "0" : "-65",
          transition: "top",
          transitionDuration: "1s",
        }}
      >
        <Container className="menu-bar" maxWidth={false}>
          <Toolbar
            className="toolbar"
            disableGutters
            sx={{
              minWidth: "100%",
              flexDirection: "inherit",
              justifyContent: "space-between",
            }}
          >
            <Box order={0} sx={{ xs: "flex", md: "none" }}>
              <div className="logo-img-container">
                <img
                  src={props.logoUrl}
                  id="logo-img"
                  className="d-inline-block align-top"
                  alt=""
                />
              </div>
            </Box>
            <Box
              order={2}
              sx={{
                justifySelf: "right",
                display: { xs: "black", md: "none" },
              }}
            >
              <React.Fragment>
                <IconButton
                  disableRipple
                  onClick={() => setOpen((state) => !state)}
                >
                  <MenuIcon
                    className="menu-icon"
                    pointerEvents="none"
                    aria-label="Delete"
                  />
                </IconButton>
              </React.Fragment>
            </Box>

            <Box
              className="page-box-md"
              sx={{
                display: { xs: "none", md: "inline-flex" },
              }}
            >
              {Routes.map((identifier) => (
                <Link
                  key={identifier.link}
                  noWrap
                  onClick={props.onMenuSelect}
                  href={identifier.link}
                  underline="none"
                >
                  <div className="buttonsurface">
                    <Typography className="buttonsurface-typopgraphy">
                      {identifier.name}
                    </Typography>
                  </div>
                </Link>
              ))}
            </Box>
          </Toolbar>
          <div className="design-element-md"></div>
        </Container>
      </AppBar>
    </>
  );
}
export default Navigation;
