import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import SoftwareentwicklungPageProps from "src/ui/Pages/Features/Softwareentwicklung/SoftwareentwicklungPageProps";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";

const FEATURE_SOFTWAREENTWICKLUNG_CONTENT_IDENTIFIER =
  "feature-softwareentwicklung";

const SoftwareentwicklungPageInteractions = {
  loadSoftwareentwicklungPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): SoftwareentwicklungPageProps => {
    return {
      loadSoftwareentwicklungContentFunction: () =>
        loadSoftwareentwicklungContent(repository, cacheEntityResolver),
    };
  },
};

export default SoftwareentwicklungPageInteractions;

async function loadSoftwareentwicklungContent(
  repository: typeof GhostRepository,
  cacheEntityResolver: CacheEntityResolver
): Promise<HtmlPanelProps> {
  const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
    () =>
      repository.findSinglePostOrPageByIdentifierAsHtml(
        FEATURE_SOFTWAREENTWICKLUNG_CONTENT_IDENTIFIER
      ),
    FEATURE_SOFTWAREENTWICKLUNG_CONTENT_IDENTIFIER
  );

  return HtmlPanelPropsMapper.map(postsOrPage);
}
