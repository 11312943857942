import { PostOrPage } from "@tryghost/content-api";
import JsonParser from "src/infrastructure/utils/JsonParser/JsonParser";
import Company from "./Company";

const CompanyMapper = {
  map: (postOrPage: PostOrPage): Company => {
    return {
      ...JsonParser.parseJson<Company>(postOrPage.plaintext!),
    };
  },
};

export default CompanyMapper;
