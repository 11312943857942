import StringUtils from "src/ui/utils/StringUtils/StringUtils";


const JsonParser = {
  parseJson: <T>(jsonString: string): T => {
    let parsableContent = StringUtils.removeNewlineAndSpace(jsonString);
    let jsonObject = JSON.parse(parsableContent);
    return jsonObject;
  },
};
export default JsonParser;
