import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage, SettingsResponse } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import GlobalAppData from "src/ui/app/GlobalAppData";
import FeaturedPageProps from "src/ui/app/Layout/Footer/FeaturedPage/FeaturedPageProps";
import FooterProps from "src/ui/app/Layout/Footer/FooterProps";
import HeaderProps from "src/ui/app/Layout/Header/HeaderProps";
import NavigationProps from "src/ui/app/Layout/Navigation/NavigationProps";
import Company from "../common/Company/Company";
import CompanyService from "../common/Company/CompanyService";
import FeaturedPagePropsMapper from "./mapping/FeaturedPagePropsMapper";
import FooterPropsMapper from "./mapping/FooterPropsMapper";
import HeaderPropsMapper from "./mapping/HeaderPropsMapper";
import NavigationPropsMapper from "./mapping/NavigationPropsMapper";
import PageSettingsMapper from "./mapping/PageSettingsMapper";
import PageSettings from "./PageSettings";

enum AppDataIdentifiers {
  PAGE_SETTINGS = "page-settings",
  FEATURED_PAGE = "featured-page",
}

const AppInteractions = {
  loadApp: async (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<GlobalAppData> => {
    const pageSettings: PageSettings = await AppInteractions.loadPageSettings(
      repository,
      cacheEntityResolver
    );
    return {
      header: await AppInteractions.loadHeader(repository, cacheEntityResolver),
      footer: await AppInteractions.loadFooter(repository, cacheEntityResolver),
      navigation: await AppInteractions.loadNavigation(
        repository,
        cacheEntityResolver
      ),
      iconUrl: pageSettings.iconUrl,
      title: pageSettings.title,
    };
  },

  loadPageSettings: async (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<PageSettings> => {
    const settingsResponse: SettingsResponse =
      await cacheEntityResolver.resolve(
        () => repository.findPageSettings(),
        AppDataIdentifiers.PAGE_SETTINGS
      );
    return PageSettingsMapper.map(settingsResponse);
  },

  loadHeader: async (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<HeaderProps> => {
    const company: Company = await CompanyService.loadCompany(
      repository,
      cacheEntityResolver
    );
    const settings: PageSettings = await AppInteractions.loadPageSettings(
      repository,
      cacheEntityResolver
    );
    return HeaderPropsMapper.map(company, settings);
  },

  loadFeaturedPage: async (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<FeaturedPageProps> => {
    const featuredPage: PostOrPage = await cacheEntityResolver.resolve(
      () =>
        repository.findSinglePostOrPageByIdentifierAsPlaintext(
          AppDataIdentifiers.FEATURED_PAGE
        ),
      AppDataIdentifiers.FEATURED_PAGE
    );
    return FeaturedPagePropsMapper.map(featuredPage);
  },

  loadFooter: async (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<FooterProps> => {
    const company: Company = await CompanyService.loadCompany(
      repository,
      cacheEntityResolver
    );
    const featuredPage: FeaturedPageProps =
      await AppInteractions.loadFeaturedPage(repository, cacheEntityResolver);
    return FooterPropsMapper.map(company, featuredPage);
  },

  loadNavigation: async (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<NavigationProps> => {
    const pageSettings: PageSettings = await AppInteractions.loadPageSettings(
      repository,
      cacheEntityResolver
    );
    return NavigationPropsMapper.map(pageSettings);
  },
};
export default AppInteractions;
