import PersonIcon from "@mui/icons-material/Person";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import TeamMemberController from "./TeamMemberController";
import TeamMemberProps from "./TeamMemberProps";
import "./team-member.css";

const TeamMember = (props: TeamMemberProps) => {
  const [contentId, setContentId] = useState("");

  const generateContentId = (title: string) => {
    return title.replaceAll("[^a-zA-Z]", "") + "-content";
  };

  useEffect(() => {
    let contentId = generateContentId(props.title);
    setContentId((id) => contentId);
    TeamMemberController.insertContent(contentId, props.content);
  }, [props]);

  return (
    <Grid className="team-member-box">
      <Grid item xs={12}>
        {props.imageUrl ? (
          <img src={props.imageUrl} alt={props.title} className="team-img" />
        ) : (
          <PersonIcon className="team-img anonymous" />
        )}
      </Grid>
      <div className="member_name_container">
        <Grid
          item
          xs={12}
          alignContent={"space-between"}
          justifyContent={"space-evenly"}
        >
          <h4 className="member-name">{props.title}</h4>
          <p id={contentId}></p>
        </Grid>
      </div>
    </Grid>
  );
};

export default TeamMember;
