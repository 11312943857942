import { useEffect, useState } from "react";
import HtmlPanel from "src/ui/components/HtmlPanel/HtmlPanel";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import ProzessautomatisierungPageProps from "./ProzessautomatisierungPageProps";

const ProzessautomatisierungPage = (props: ProzessautomatisierungPageProps) => {
  const [prozessautomatisierungContent, setProzessautomatisierungContent] =
    useState<HtmlPanelProps>();

  useEffect(() => {
    props
      .loadProzessautomatisierungContentFunction()
      .then(setProzessautomatisierungContent);
  }, [props]);

  return (
    <HtmlPanel id="prozessautomatisierung" {...prozessautomatisierungContent} />
  );
};

export default ProzessautomatisierungPage;
