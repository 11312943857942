import TeamMemberProps from "./TeamMember/TeamMemberProps";
import TeamMemberGridProperties from "./TeamMemberGridProperties";

const TeamController = {
  calculateGridProperties: (teamMembers: TeamMemberProps[]): TeamMemberGridProperties => {
    const membersCount = teamMembers.length;
    const xs = 12;
    // wenn die Anzahl Team-Mitglieder durch 4 teilbar ist, sollen 4 Mitglieder pro Zeile dargestellt werden
    // ansonsten sollen (bis zu) 3 Team-Mitglieder pro Zeile dargestellt werden
    const lg = membersCount % 4 === 0 ? 3 : 4;
    // wenn die Anzahl Team-Mitglieder durch 3 teilbar ist, sollen 3 Mitglieder pro Zeile dargestellt werden
    // ansonsten sollen (bis zu) 2 Team-Mitglieder pro Zeile dargestellt werden
    const md = membersCount % 3 === 0 ? 4 : 6;

    return {
      lg: lg,
      md: md,
      xs: xs
    };
  }
}
export default TeamController;