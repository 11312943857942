import { Box, Skeleton } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import { useEffect, useId } from "react";
import HtmlReplacer from "src/ui/utils/HtmlReplacer";
import HtmlPanelProps from "./HtmlPanelProps";

const HtmlPanel = (props: HtmlPanelProps & CommonProps & { id: string }) => {
  const contentId = useId();
  useEffect(() => {
    props.html && HtmlReplacer.replaceHtml(props.html, contentId, "inner");
  }, [props.html, contentId]);

  return (
    <Box id={props.id} className="section">
      <div className="section-title">
        <h2>{props.title}</h2>
      </div>
      <div id={contentId}>
        <Skeleton className="skeleton" />
      </div>
    </Box>
  );
};
export default HtmlPanel;
