import { Link, Stack } from "@mui/material";
import FeaturedPageProps from "src/ui/app/Layout/Footer/FeaturedPage/FeaturedPageProps";
import "./featured-page.css";

const FeaturedPage = (props: FeaturedPageProps) => {
  return (
    <Stack alignItems="center" className="footer-col col-xs-12 featured-page">
      <div>{props.text}</div>
      <a href={props.targetUrl} target="_blank" rel="noreferrer">
        {props.targetUrl}
      </a>
      <Link href={props.targetUrl} target="_blank" flexDirection="column">
        <img src={props.imageUrl} className="featured-page-image" alt="" />
      </Link>
    </Stack>
  );
};
export default FeaturedPage;
