const HtmlReplacer = {
  replaceHtml: (
    html: string,
    elementId: string,
    replaceType: "inner" | "outer" = "inner"
  ): void => {
    let element = document.getElementById(elementId);
    if (element) {
      if (replaceType === "outer") {
        element.outerHTML = html;
      } else {
        element.innerHTML = html;
      }
    }
  },
};
export default HtmlReplacer;
