import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import Company from "./Company";
import CompanyMapper from "./CompanyMapper";

const COMPANY_IDENTIFIER = "company";

const CompanyService = {
  loadCompany: async (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<Company> => {
    const postOrPage: PostOrPage = await cacheEntityResolver.resolve(
      () =>
        repository.findSinglePostOrPageByIdentifierAsPlaintext(
          COMPANY_IDENTIFIER
        ),
      COMPANY_IDENTIFIER
    );
    return CompanyMapper.map(postOrPage);
  },
};

export default CompanyService;
