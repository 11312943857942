import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Button } from "@mui/material";
import { useEffect, useRef } from "react";
import HeaderController from "./HeaderController";
import HeaderProps from "./HeaderProps";
import "./desktopAndTablet.css";
import "./header.css";
import "./landscapeMobile.css";

const Header = (props: HeaderProps) => {
  const headerRef: React.RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);

  useEffect(() => {
    HeaderController.setBackgroundImage(headerRef, props.coverImageUrl);
  }, [props.coverImageUrl]);

  return (
    <header id="header" ref={headerRef}>
      <div className="container-fluid">
        <div className="row intro">
          <div className="col-xs-12">
            <div className="div-centered div-company-logo">
              <img
                src={props.logoUrl}
                className="logo-image"
                alt="THB-IT Services Logo"
                width="80%"
              />
            </div>
            <div className="div-centered div-column div-header-buttons">
              <div className="div-row div-contact-buttons">
                <Button
                  href={HeaderController.toPhoneUrl(props.phone)}
                  variant="contained"
                  startIcon={<PhoneIcon />}
                  className="cta-button"
                >
                  {props.phone}
                </Button>
                <Button
                  href={HeaderController.toMailUrl(props.email)}
                  variant="contained"
                  startIcon={<EmailIcon />}
                  className="cta-button"
                >
                  {props.email}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
