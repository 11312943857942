const StringUtils = {
  removeNewlineAndSpace: function (value: string): string {
    return value.replace(/[\r\n]\s*/gm, "");
  },
  isEmpty: function (s: string | undefined | null): boolean {
    if (s) {
      return "" === s.trim();
    }
    return false;
  },
};
export default StringUtils;
