import { useEffect, useState } from "react";
import HtmlPanel from "src/ui/components/HtmlPanel/HtmlPanel";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import ImplementierungPageProps from "./ImplementierungPageProps";

const ImplementierungPage = (props: ImplementierungPageProps) => {
  const [implementierungContent, setImplementierungContent] =
    useState<HtmlPanelProps>();

  useEffect(() => {
    props.loadImplementierungContentFunction().then(setImplementierungContent);
  }, [props]);

  return <HtmlPanel id="implementation" {...implementierungContent} />;
};

export default ImplementierungPage;
