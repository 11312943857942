import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import LegalNotesPageProps from "src/ui/Pages/legal/LegalNotes/LegalNotesPageProps";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";

const LEGAL_NOTES_IDENTIFIER = "legal-notes";

const LegalNotesPageInteractions = {
  loadLegalNotesPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): LegalNotesPageProps => {
    return {
      loadLegalNotesContentFunction: () =>
        loadLegalNotesContent(repository, cacheEntityResolver),
    };
  },
};

export default LegalNotesPageInteractions;

async function loadLegalNotesContent(
  repository: typeof GhostRepository,
  cacheEntityResolver: CacheEntityResolver
): Promise<HtmlPanelProps> {
  const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
    () =>
      repository.findSinglePostOrPageByIdentifierAsHtml(LEGAL_NOTES_IDENTIFIER),
    LEGAL_NOTES_IDENTIFIER
  );
  return HtmlPanelPropsMapper.map(postsOrPage);
}
