const DataReceivedHandlerRegistration = {
  registerDataReceivedHandler: <T>(
    promise: Promise<T>,
    callback: (response: T) => void
  ): void => {
    promise.then((response) => {
      callback(response);
    });
  },
};
export default DataReceivedHandlerRegistration;
