import "./MemberCardLeft.css";
import "./svg-styles.css";

interface Props {
  nameLeft: string;
  imageLeftAlt: string;
  imageLeftSrc: string;
  quotationLeft: string;
  jobTitleLeft: string;
}

const MemberCardLeft = (propsLeft: Props) => {
  return (
    <div className={`member-card-left-member-card-left`}>
      <div className="member-card-left-card-left">
        <span className="member-card-left-name-left">{propsLeft.nameLeft}</span>
        <span className="member-card-left-job-title-left">
          {propsLeft.jobTitleLeft}
        </span>
        <img
          alt={propsLeft.imageLeftAlt}
          src={propsLeft.imageLeftSrc}
          className="member-card-left-image-left"
        />
        <span className="member-card-left-quotation-left">
          {propsLeft.quotationLeft}
        </span>
        <div className="member-card-left-container-left">
          <div className="member-card-left-container1-left">
            <svg
              id="Ebene_1"
              data-name="Ebene 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 121.11 139.85"
            >
              <defs></defs>
              <polygon
                className="cls-1"
                points="5.27 38 5.27 101.84 60.56 133.76 115.84 101.84 115.84 38 60.56 6.08 5.27 38"
              />
              <path
                className="cls-2"
                d="M53.24,99.44,57,101.56a2.5,2.5,0,0,0,3.13-.14L68.77,96c2.5-1.7,4.08-3.12,4.44-5.91a3.84,3.84,0,0,0-.22-2.2c-.17-.45-1.52.13-2.13.5C66.65,91,62.5,93.6,58.33,96.2c-1.63,1-3.16,2-5.09,3.24M91.8,98.27a4.77,4.77,0,0,0-.45-.54c-1.14-1-2.51-2.64-6-1.31,0,0-11.59,6.93-17.76,10.68l2.24,1.29c1,.66,3.38,1.27,4.37.63,4.24-2.73,8.74-5.17,13-7.84,1.53-1,3-1.93,4.63-2.91m25.07-.35c-4.32,2.7-7.19,4.53-11.21,7-10.26,6.42-17.93,11.16-28.2,17.59,0,0-4.91,4-6,3.39L28.27,101c-.27-.14-1.48-.87-2-1.17C27,99.37,27.46,99,28,98.65c13.73-8.58,28.76-17.14,42.46-25.76a2.6,2.6,0,0,1,3.09,0l13.38,7.72c3.35,2.11-3.19,8.29-3.19,8.29s6.18-2.66,9.4-3.67c1.41-.44,3.16.83,4.52,1.58"
                transform="translate(-10.57 -1.18)"
              />
              <path
                className="cls-2"
                d="M71.88,16.26c4.21,2.42,8,4.56,11.77,6.84a6,6,0,0,1,1.87,2.57q.08,9,0,18.09a2.43,2.43,0,0,0,1.36,2.49c5.72,3.24,10.27,5.81,16,9.1.35.19.76.3,1.45.58V35c2.91,1.67,5,2.77,8,4.49,4.81,2.54,4.91,4.09,4.82,8.25C116.77,62.47,117,77.22,117,92V94.3l-3.46-2c-2.59-1.48-5.15-3-7.78-4.41a2.29,2.29,0,0,1-1.33-2.32c0-6,0-12.06,0-18.09a2.37,2.37,0,0,0-1.28-2.37c-5.64-3.25-10.14-5.8-15.78-9.06a15.89,15.89,0,0,0-1.76-.74v21c-3.19-1.82-8-5.1-10.78-6.7-3-1.36-3.09-2.34-3-5.65.22-15.17.09-30.34.09-45.51Z"
                transform="translate(-10.57 -1.18)"
              />
              <path
                className="cls-2"
                d="M24.47,59.45c0-4.63,0-10.76.1-15.38a3,3,0,0,1,1.21-2.13C35.86,36.05,46,30.23,56.12,24.39c3.79-2.19,7.57-4.41,11.35-6.6.56-.33,1.27-.72,2.12-1.16V27.44c0,1.94-1,5.67-3.44,7-1,.74-6.91,4.22-7.09,4.32l-1.22.73s-1.48.87-1.77,1.08a19.4,19.4,0,0,1-2.69,1.58c-.05,1.46,0,2.18,0,3.65,0,10.17,0,20.35,0,30.53,0,1.17,0,3.36-1.12,4l-11.38,7c0-12.56-.13-24.76-.13-37.41-3.47,1.88-3.82,2.15-3.82,2.15l-.86.48"
                transform="translate(-10.57 -1.18)"
              />
              <path
                className="cls-1"
                d="M71.12,141,71,141,10.57,106.07V36.14l.14-.08L71.12,1.18l60.56,35v69.93l-.15.08Zm-60-35.3,60,34.62,60-34.62V36.48l-60-34.62-60,34.62Z"
                transform="translate(-10.57 -1.18)"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberCardLeft;
