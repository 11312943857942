import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useRef, useState } from "react";
import Testimonial from "./Testimonial/Testimonial";
import "./Testimonials.css";
import TestimonialsController from "./TestimonialsController";
import TestimonialsProps from "./TestimonialsProps";

const Testimonials = (props: TestimonialsProps) => {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const containerRef: React.RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.testimonials) {
      TestimonialsController.setBackgroundImage(
        containerRef,
        props.testimonials[currentTestimonialIndex].imageUrl
      );
    }
  }, [props.testimonials, currentTestimonialIndex]);

  function renderSkeletons() {
    return (
      <>
        <Skeleton />
      </>
    );
  }

  function renderTestimonials() {
    return (
      <Grid
        id="testimonials"
        container
        maxWidth={"100%"}
        direction="row"
        justifyContent="center"
        justifyItems="center"
        alignItems="stretch"
        ref={containerRef}
      >
        <Grid item xs={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems={"center"}
            flexDirection="column"
            className="arrow-icon-container"
            onClick={() =>
              setCurrentTestimonialIndex(
                TestimonialsController.nextTestimonial(
                  currentTestimonialIndex,
                  props.testimonials!.length
                )
              )
            }
          >
            <ArrowBackIosNewIcon className="arrow-icon" />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Testimonial {...props.testimonials![currentTestimonialIndex]} />
        </Grid>
        <Grid item xs={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems={"center"}
            flexDirection="column"
            className="arrow-icon-container"
            onClick={() =>
              setCurrentTestimonialIndex(
                TestimonialsController.previousTestimonial(
                  currentTestimonialIndex,
                  props.testimonials!.length
                )
              )
            }
          >
            <ArrowForwardIosIcon className="arrow-icon" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box id="testimonials-container" className="section text-center">
      <div className="section-title">
        <h2>Das sagen unsere Kunden</h2>
      </div>

      {props.testimonials ? renderTestimonials() : renderSkeletons()}
    </Box>
  );
};

export default Testimonials;
