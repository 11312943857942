import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { createRoot } from "react-dom/client";
import "./index.css";
import GhostRepository from "./infrastructure/ghost/GhostRepository";
import Interactions from "./integration/Interactions";
import * as serviceWorker from "./serviceWorker";
import App from "./ui/app/App";
import FeatureCoachingPage from "./ui/Pages/Features/Coaching/CoachingPage";
import ProzessautomatisierungPage from "./ui/Pages/Features/Prozessautomatisierung/ProzessautomatisierungPage";
import SoftwareentwicklungPage from "./ui/Pages/Features/Softwareentwicklung/SoftwareentwicklungPage";
import FeatureTrainingPage from "./ui/Pages/Features/Training/TrainingPage";
import ImpressumPage from "./ui/Pages/legal/Impressum/ImpressumPage";
import LegalNotesPage from "./ui/Pages/legal/LegalNotes/LegalNotesPage";
import PrivacyPolicyPage from "./ui/Pages/legal/PrivacyPolicy/PrivacyPolicyPage";
import MainPage from "./ui/Pages/Main/MainPage";
import AnforderungsanalysePage from "./ui/Pages/Services/Anforderungsanalyse/AnforderungsanalysePage";
import ServiceCoachingPage from "./ui/Pages/Services/Coaching/CoachingPage";
import ImplementierungPage from "./ui/Pages/Services/Implementierung/ImplementierungPage";
import SystemUndSoftwareArchitekturPage from "./ui/Pages/Services/SystemUndSoftwareArchitektur/SystemUndSoftwareArchitekturPage";
import TechnologieauswahlUndEvaluationPage from "./ui/Pages/Services/TechnologieauswahlUndEvaluation/TechnologieauswahlUndEvaluationPage";
import ServiceTrainingPage from "./ui/Pages/Services/Training/TrainingPage";

const CACHE_STORE =
  process.env.REACT_APP_ENABLE_CACHING === "true" ? localStorage : undefined;
const CACHE = new CacheEntityResolver(CACHE_STORE);
const REPOSITORY = GhostRepository;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <App
    loadAppDataFunction={() => Interactions.loadApplication(REPOSITORY, CACHE)}
    mainPage={<MainPage {...Interactions.loadMainPage(REPOSITORY, CACHE)} />}
    impressumPage={
      <ImpressumPage {...Interactions.loadImpressumPage(REPOSITORY, CACHE)} />
    }
    legalNotesPage={
      <LegalNotesPage {...Interactions.loadLegalNotesPage(REPOSITORY, CACHE)} />
    }
    privacyPolicyPage={
      <PrivacyPolicyPage
        {...Interactions.loadPrivacyPolicyPage(REPOSITORY, CACHE)}
      />
    }
    featureCoachingPage={
      <FeatureCoachingPage
        {...Interactions.loadFeatureCoachingPage(REPOSITORY, CACHE)}
      />
    }
    featureProzessautomatisierungPage={
      <ProzessautomatisierungPage
        {...Interactions.loadFeatureProzessautomatisierungPage(
          REPOSITORY,
          CACHE
        )}
      />
    }
    featureSoftwareentwicklungPage={
      <SoftwareentwicklungPage
        {...Interactions.loadFeatureSoftwareentwicklungPage(REPOSITORY, CACHE)}
      />
    }
    featureTrainingPage={
      <FeatureTrainingPage
        {...Interactions.loadFeatureTrainingPage(REPOSITORY, CACHE)}
      />
    }
    serviceImplementierungPage={
      <ImplementierungPage
        {...Interactions.loadServiceImplementierungPage(REPOSITORY, CACHE)}
      />
    }
    serviceTechnologieauswahlUndEvaluationPage={
      <TechnologieauswahlUndEvaluationPage
        {...Interactions.loadServiceTechnologieauswahlUndEvaluationPage(
          REPOSITORY,
          CACHE
        )}
      />
    }
    serviceTrainingPage={
      <ServiceTrainingPage
        {...Interactions.loadServiceTrainingPage(REPOSITORY, CACHE)}
      />
    }
    serviceSystemUndSoftwareArchitekturPage={
      <SystemUndSoftwareArchitekturPage
        {...Interactions.loadServiceSystemUndSoftwareArchitekturPage(
          REPOSITORY,
          CACHE
        )}
      />
    }
    serviceCoachingPage={
      <ServiceCoachingPage
        {...Interactions.loadServiceCoachingPage(REPOSITORY, CACHE)}
      />
    }
    serviceAnforderungsanalysePage={
      <AnforderungsanalysePage
        {...Interactions.loadServiceAnforderungsanalysePage(REPOSITORY, CACHE)}
      />
    }
  />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
