import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import ProzessautomatisierungPageProps from "src/ui/Pages/Features/Prozessautomatisierung/ProzessautomatisierungPageProps";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";

const FEATURE_PROZESSAUTOMATISIERUNG_CONTENT_IDENTIFIER =
  "feature-prozessautomatisierung";

const ProzessautomatisierungPageInteractions = {
  loadProzessautomatisierungPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): ProzessautomatisierungPageProps => {
    return {
      loadProzessautomatisierungContentFunction: () =>
        loadProzessautomatisierungContent(repository, cacheEntityResolver),
    };
  },
};

export default ProzessautomatisierungPageInteractions;

async function loadProzessautomatisierungContent(
  repository: typeof GhostRepository,
  cacheEntityResolver: CacheEntityResolver
): Promise<HtmlPanelProps> {
  const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
    () =>
      repository.findSinglePostOrPageByIdentifierAsHtml(
        FEATURE_PROZESSAUTOMATISIERUNG_CONTENT_IDENTIFIER
      ),
    FEATURE_PROZESSAUTOMATISIERUNG_CONTENT_IDENTIFIER
  );

  return HtmlPanelPropsMapper.map(postsOrPage);
}
