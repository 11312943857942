import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/system";
import Project from "./project/Project";
import ProjectProps from "./project/ProjectProps";
import "./projects.css";
import ProjectsProps from "./ProjectsProps";

const Projects = (props: ProjectsProps) => {
  function renderSkeleton() {
    return (
      <>
        <Grid item xs={12}>
          <Skeleton />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton />
        </Grid>
      </>
    );
  }

  function renderSingleProject(projectProps: ProjectProps, index: number) {
    return <Project {...projectProps} index={index} />;
  }

  function renderProjects() {
    return props.projects!.map((projectProps: ProjectProps, index: number) => {
      return (
        <Grid item xs={12} sm={12} md={6} xl={6} key={index}>
          {renderSingleProject(projectProps, index)}
        </Grid>
      );
    });
  }

  return (
    <Box id="projects" className="section text-center">
      <div className="section-title">
        <h2>Kundenprojekte</h2>
      </div>
      <Grid container spacing={3} alignItems={"flex-start"}>
        {props.projects ? renderProjects() : renderSkeleton()}
      </Grid>
    </Box>
  );
};

export default Projects;
