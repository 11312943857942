import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import ContactProps from "src/ui/Pages/Main/Contact/ContactProps";
import FeaturesProps from "src/ui/Pages/Main/Features/FeaturesProps";
import MainPageProps from "src/ui/Pages/Main/MainPageProps";
import ProjectsProps from "src/ui/Pages/Main/Projects/ProjectsProps";
import ServicesProps from "src/ui/Pages/Main/Services/ServicesProps";
import TeamProps from "src/ui/Pages/Main/Team/TeamProps";
import TestimonialsProps from "src/ui/Pages/Main/Testimonials/TestimonialsProps";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import GhostRepository from "../../infrastructure/ghost/GhostRepository";
import Company from "../common/Company/Company";
import CompanyService from "../common/Company/CompanyService";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";
import ContactPropsMapper from "./mapping/ContactPropsMapper";
import FeaturesPropsMapper from "./mapping/FeaturesPropsMapper";
import ProjectsPropsMapper from "./mapping/ProjectsPropsMapper";
import ServicesPropsMapper from "./mapping/ServicesPropsMapper";
import TeamPropsMapper from "./mapping/TeamPropsMapper";
import TestimonialsPropsMapper from "./mapping/TestimonialsPropsMapper";

enum MainPageDataIdentifiers {
  ABOUT = "about",
  FEATURE = "feature",
  PROJECT = "project",
  SERVICE = "service",
  TEAM = "team",
  TESTIMONIALS = "testimonials",
}

const MainPageInteractions = {
  loadMainPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): MainPageProps => {
    return {
      loadFeaturesFunction: () =>
        MainPageInteractions.loadFeatures(
          repository,
          MainPageDataIdentifiers.FEATURE,
          cacheEntityResolver
        ),
      loadProjectsFunction: () =>
        MainPageInteractions.loadProjects(
          repository,
          MainPageDataIdentifiers.PROJECT,
          cacheEntityResolver
        ),
      loadServicesFunction: () =>
        MainPageInteractions.loadServices(
          repository,
          MainPageDataIdentifiers.SERVICE,
          cacheEntityResolver
        ),
      loadTeamFunction: () =>
        MainPageInteractions.loadTeam(
          repository,
          MainPageDataIdentifiers.TEAM,
          cacheEntityResolver
        ),
      loadTestimonialsFunction: () =>
        MainPageInteractions.loadTestimonials(
          repository,
          MainPageDataIdentifiers.TESTIMONIALS,
          cacheEntityResolver
        ),
      loadContactFunction: () =>
        MainPageInteractions.loadContact(repository, cacheEntityResolver),
      loadAboutFunction: () =>
        MainPageInteractions.loadAbout(
          repository,
          MainPageDataIdentifiers.ABOUT,
          cacheEntityResolver
        ),
    };
  },
  loadProjects: async (
    repository: typeof GhostRepository,
    dataIdentifier: string,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<ProjectsProps> => {
    const postsOrPages: PostOrPage[] = await cacheEntityResolver.resolve(
      () =>
        repository.findMultiplePostsOrPagesByIdentifierAsPlaintextSortedByHiddenTag(
          MainPageDataIdentifiers.PROJECT
        ),
      dataIdentifier
    );

    return ProjectsPropsMapper.map(postsOrPages);
  },

  loadServices: async (
    repository: typeof GhostRepository,
    dataIdentifier: string,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<ServicesProps> => {
    const postsOrPages: PostOrPage[] = await cacheEntityResolver.resolve(
      () =>
        repository.findMultiplePostsOrPagesByIdentifierAsHtmlSortedByHiddenTag(
          dataIdentifier
        ),
      dataIdentifier
    );
    return ServicesPropsMapper.map(postsOrPages);
  },

  loadFeatures: async (
    repository: typeof GhostRepository,
    dataIdentifier: string,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<FeaturesProps> => {
    const postsOrPages: PostOrPage[] = await cacheEntityResolver.resolve(
      () =>
        repository.findMultiplePostsOrPagesByIdentifierAsHtmlSortedByHiddenTag(
          dataIdentifier
        ),
      dataIdentifier
    );
    return FeaturesPropsMapper.map(postsOrPages);
  },

  loadTeam: async (
    repository: typeof GhostRepository,
    dataIdentifier: string,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<TeamProps> => {
    const postsOrPages: PostOrPage[] = await cacheEntityResolver.resolve(
      () =>
        repository.findMultiplePostsOrPagesByIdentifierAsHtmlSortedByHiddenTag(
          dataIdentifier
        ),
      dataIdentifier
    );
    return TeamPropsMapper.map(postsOrPages);
  },

  loadTestimonials: async (
    repository: typeof GhostRepository,
    dataIdentifier: string,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<TestimonialsProps> => {
    const postsOrPages: PostOrPage[] = await cacheEntityResolver.resolve(
      () =>
        repository.findMultiplePostsOrPagesByIdentifierAsPlaintext(
          MainPageDataIdentifiers.TESTIMONIALS
        ),
      dataIdentifier
    );

    return TestimonialsPropsMapper.map(postsOrPages);
  },

  loadContact: async (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<ContactProps> => {
    const company: Company = await CompanyService.loadCompany(
      repository,
      cacheEntityResolver
    );

    return ContactPropsMapper.map(company);
  },

  loadAbout: async (
    repository: typeof GhostRepository,
    dataIdentifier: string,
    cacheEntityResolver: CacheEntityResolver
  ): Promise<HtmlPanelProps> => {
    const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
      () => repository.findSinglePostOrPageByIdentifierAsHtml(dataIdentifier),
      dataIdentifier
    );

    return HtmlPanelPropsMapper.map(postsOrPage);
  },
};

export default MainPageInteractions;
