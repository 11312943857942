import { PostOrPage } from "@tryghost/content-api";
import JsonParser from "src/infrastructure/utils/JsonParser/JsonParser";
import FeaturedPageProps from "src/ui/app/Layout/Footer/FeaturedPage/FeaturedPageProps";

const FeaturedPagePropsMapper = {
  map: (postOrPage: PostOrPage): FeaturedPageProps => {
    return JsonParser.parseJson(postOrPage.plaintext!);
  },
};

export default FeaturedPagePropsMapper;
