import { PostOrPage, SettingsResponse } from "@tryghost/content-api";
import GhostService from "./GhostService";

const GhostRepository = {
  findSinglePostOrPageByIdentifierAsPlaintext: async function (
    id: string
  ): Promise<PostOrPage> {
    return GhostService.findPostOrPageBySlug(id, "plaintext");
  },

  findSinglePostOrPageByIdentifierAsHtml: async function (
    id: string
  ): Promise<PostOrPage> {
    return GhostService.findPostOrPageBySlug(id, "html");
  },

  findMultiplePostsOrPagesByIdentifierAsPlaintext: async function (
    id: string
  ): Promise<PostOrPage[]> {
    return GhostService.findPostsOrPagesByTag(id, "plaintext");
  },

  findMultiplePostsOrPagesByIdentifierAsHtml: async function (
    id: string
  ): Promise<PostOrPage[]> {
    return GhostService.findPostsOrPagesByTag(id, "html");
  },

  findPageSettings: async function (): Promise<SettingsResponse> {
    return GhostService.findSettings();
  },

  findMultiplePostsOrPagesByIdentifierAsHtmlSortedByHiddenTag: async function (
    id: string
  ) {
    const postsOrPages = await this.findMultiplePostsOrPagesByIdentifierAsHtml(
      id
    );
    return GhostService.sortByHiddenIndexTag(postsOrPages);
  },

  findMultiplePostsOrPagesByIdentifierAsPlaintextSortedByHiddenTag:
    async function (id: string) {
      const postsOrPages =
        await this.findMultiplePostsOrPagesByIdentifierAsPlaintext(id);
      return GhostService.sortByHiddenIndexTag(postsOrPages);
    },
  // findProjects: async (): Promise<PostOrPage[]> => {
  //   const postsOrPages = await GhostService.findPostsOrPagesByTag(
  //     "project",
  //     "plaintext"
  //   );
  //   return GhostService.sortByHiddenIndexTag(postsOrPages);
  // },
};

export default GhostRepository;
