import { CommonProps } from "@mui/material/OverridableComponent";
import "./Testimonial.css";
import TestimonialProps from "./TestimonialProps";

export const Testimonial = (props: TestimonialProps & CommonProps) => {
  return (
    <>
      <div className="testimonial-panel">
        <h3 className="source">{props.source}</h3>
        <p className="content">{props.content}</p>
      </div>
    </>
  );
};
export default Testimonial;
