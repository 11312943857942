import { ArrowRight } from "@mui/icons-material";
import { List, ListItem, ListItemText } from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import "./project.css";
import ProjectProps from "./ProjectProps";

const ProjectDetails = (props: ProjectProps) => {
  return (
    <>
      <div className="project-detail-box">
        <List>
          {props.descriptions.map((description, index) => {
            return (
              <ListItem key={index}>
                <ListItemAvatar>
                  <ArrowRight fontSize="large" />
                </ListItemAvatar>
                <ListItemText style={{ fontSize: "15pt" }} disableTypography>
                  {description}
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
        <div className="project-detail-box-technologies">
          {props.technologies.map((technology, index) => {
            const suffix = index < props.technologies.length - 1 ? " | " : "";
            return <span key={index}>{technology + suffix}</span>;
          })}
        </div>
      </div>
    </>
  );
};
export default ProjectDetails;
