import { createTheme } from "@mui/material";

const AppTheme = createTheme({
  palette: {
    primary: {
      main: "#003a6a",
    },
    secondary: { main: "#6a003a" },
    text: { primary: "#626262" },
  },
});

export default AppTheme;
