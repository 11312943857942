import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/system";
import GoogleMapsLink from "../../../components/GoogleMapsLink/GoogleMaplsLink";
import ContactProps from "./ContactProps";
import ContactForm from "./components/ContactForm/ContactForm";
import SocialBar from "./components/SocialBar/SocialBar";
import "./contact.css";

export const Contact = (props: ContactProps) => {
  function renderSkelekton() {
    return (
      <div className="col-md-8">
        <Skeleton />
      </div>
    );
  }

  function renderContactInformation() {
    return (
      <>
        <div className="col-md-8">
          <div className="row">
            <div className="section-title">
              <h2>Schreiben Sie uns!</h2>
            </div>
            <ContactForm />
          </div>
        </div>
        <div className="col-md-3 col-md-offset-1 contact-info">
          <div className="contact-item">
            <h3>Kontakt</h3>
            {props.address && props.googleMapsLink && (
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                <span className="googlemaps-link">
                  <GoogleMapsLink
                    url={props.googleMapsLink}
                    displayText={props.address}
                  />
                </span>
              </p>
            )}
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-phone"></i> Phone
              </span>{" "}
              <a href={"tel:" + props.phone} className="phonenumber_link">
                {props.phone}
              </a>
            </p>
          </div>
          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-envelope-o"></i> Email
              </span>{" "}
              <a href={"mailto:" + props.email} className="email_link">
                {props.email}
              </a>
            </p>
          </div>
        </div>
        {props.socialAccounts && (
          <div className="social-bar">
            <SocialBar
              xing={props.socialAccounts.xing}
              facebook={props.socialAccounts.facebook}
              linkedin={props.socialAccounts.linkedin}
              twitter={props.socialAccounts.twitter}
              youtube={props.socialAccounts.youtube}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <Box id="contact" className="section">
      <div className="section-title">
        {props.address ? renderContactInformation() : renderSkelekton()}
      </div>
    </Box>
  );
};
