import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import SystemUndSoftwareArchitekturPageProps from "src/ui/Pages/Services/SystemUndSoftwareArchitektur/SystemUndSoftwareArchitekturPageProps";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";

const SERVICE_SYSTEM_UND_SOFTWARE_ARCHITEKTUR_CONTENT_IDENTIFIER =
  "service-system-und-software-architektur";

const SystemUndSoftwareArchitekturPageInteractions = {
  loadSystemUndSoftwareArchitekturPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): SystemUndSoftwareArchitekturPageProps => {
    return {
      loadSystemUndSoftwareArchitekturContentFunction: () =>
        loadSystemUndSoftwareArchitekturContent(
          repository,
          cacheEntityResolver
        ),
    };
  },
};

export default SystemUndSoftwareArchitekturPageInteractions;

async function loadSystemUndSoftwareArchitekturContent(
  repository: typeof GhostRepository,
  cacheEntityResolver: CacheEntityResolver
): Promise<HtmlPanelProps> {
  const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
    () =>
      repository.findSinglePostOrPageByIdentifierAsHtml(
        SERVICE_SYSTEM_UND_SOFTWARE_ARCHITEKTUR_CONTENT_IDENTIFIER
      ),
    SERVICE_SYSTEM_UND_SOFTWARE_ARCHITEKTUR_CONTENT_IDENTIFIER
  );

  return HtmlPanelPropsMapper.map(postsOrPage);
}
