import { useEffect, useState } from "react";
import HtmlPanel from "src/ui/components/HtmlPanel/HtmlPanel";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import ImpressumPageProps from "./ImpressumPageProps";

const ImpressumPage = (props: ImpressumPageProps) => {
  const [impressumContent, setImpressumContent] = useState<HtmlPanelProps>();

  useEffect(() => {
    props.loadImpressumContentFunction().then(setImpressumContent);
  }, [props]);

  return <HtmlPanel id="impressum" {...impressumContent} />;
};

export default ImpressumPage;
