import { PostOrPage, SettingsResponse } from "@tryghost/content-api";
import JsonParser from "src/infrastructure/utils/JsonParser/JsonParser";
import GhostApi from "./GhostApi";

const GhostService = {
  findPostOrPageBySlug: async (
    slug: string,
    format: "plaintext" | "html" = "plaintext"
  ): Promise<PostOrPage> => {
    return new Promise<PostOrPage>((resolve, reject) => {
      GhostApi.posts
        .browse({ filter: "slug:" + slug, formats: format })
        .then((received) => {
          resolve(received.at(0)!);
        });
    });
  },
  findPostsOrPagesByTag: async (
    tag: string,
    format: "plaintext" | "html" = "plaintext"
  ): Promise<PostOrPage[]> => {
    return new Promise<PostOrPage[]>((resolve, reject) => {
      GhostApi.posts
        .browse({ filter: "tags:" + tag, formats: format, include: "tags" })
        .then((received) => {
          resolve(received!);
        });
    });
  },
  retrieveContentAsJsonObjectBySlug: async <T>(slug: string): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
      GhostService.findPostOrPageBySlug(slug).then((postOrPage) => {
        let jsonObject = JsonParser.parseJson<T>(postOrPage.plaintext!);
        resolve(jsonObject);
      });
    });
  },
  findSettings: async (): Promise<SettingsResponse> => {
    return GhostApi.settings.browse().then((response) => {
      return response;
    });
  },
  retrieveContentAsHtml: async (slug: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      GhostService.findPostOrPageBySlug(slug, "html").then((postOrPage) => {
        resolve(postOrPage.html!);
      });
    });
  },

  sortByHiddenIndexTag: (postsOrPages: PostOrPage[]): PostOrPage[] => {
    return postsOrPages.sort((a, b) => {
      const aHiddenIndex = GhostService.retrieveHiddenIndexTag(a);
      const bHiddenIndex = GhostService.retrieveHiddenIndexTag(b);
      return aHiddenIndex.localeCompare(bHiddenIndex);
    });
  },

  retrieveHiddenIndexTag: (postOrPage: PostOrPage): string => {
    return postOrPage.tags!.find((tag) => tag.visibility === "internal")!.name!;
  },
};
export default GhostService;
