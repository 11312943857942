import { useEffect, useState } from "react";
import HtmlPanel from "src/ui/components/HtmlPanel/HtmlPanel";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import TrainingPageProps from "./TrainingPageProps";

const TrainingPage = (props: TrainingPageProps) => {
  const [trainingContent, setTrainingContent] = useState<HtmlPanelProps>();

  useEffect(() => {
    props.loadTrainingContentFunction().then(setTrainingContent);
  }, [props]);

  return <HtmlPanel id="training" {...trainingContent} />;
};

export default TrainingPage;
