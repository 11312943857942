import BackgroundImageReplacer from "src/ui/utils/BackgroundImageReplacer";

const TestimonialsController = {
  setBackgroundImage: (
    ref: React.RefObject<HTMLDivElement>,
    imageUrl?: string
  ): void => {
    BackgroundImageReplacer.setBackgroundImage(
      ref,
      "testimonial-background",
      imageUrl
    );
  },

  nextTestimonial: (
    currentIndex: number,
    totalNumberOfTestimonials: number
  ): number => {
    return currentIndex === totalNumberOfTestimonials - 1
      ? 0
      : currentIndex + 1;
  },

  previousTestimonial: (
    currentIndex: number,
    totalNumberOfTestimonials: number
  ): number => {
    return currentIndex === 0
      ? totalNumberOfTestimonials - 1
      : currentIndex - 1;
  },
};
export default TestimonialsController;
