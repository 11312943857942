import Company from "src/integration/common/Company/Company";
import ContactProps from "src/ui/Pages/Main/Contact/ContactProps";

const ContactPropsMapper = {
  map: (company: Company): ContactProps => {
    return {
      ...company,
    };
  },
};

export default ContactPropsMapper;
