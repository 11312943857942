import { PostOrPage } from "@tryghost/content-api";
import FeatureProps from "src/ui/Pages/Main/Features/feature/FeatureProps";
import FeaturesProps from "src/ui/Pages/Main/Features/FeaturesProps";

const FeaturesPropsMapper = {
  map: (postsOrPages: PostOrPage[]): FeaturesProps => {
    const domParser: DOMParser = new DOMParser();
    const features: FeatureProps[] = postsOrPages.map((featurePost) => {
      const document = domParser.parseFromString(
        featurePost.html!,
        "text/html"
      );
      const icon = document.getElementsByTagName("svg")[0];
      const content = document.getElementsByTagName("p")[0];
      return {
        slug: featurePost.slug!,
        title: featurePost.title!,
        content: content.innerHTML,
        icon: icon.outerHTML,
      };
    });
    return {
      features: features,
    };
  },
};
export default FeaturesPropsMapper;
