import { Box, Grid } from "@mui/material";

import "./about-page.css";
import MemberCardLeft from "./AboutMemberCards/MemberCardLeft";
import MemberCardRight from "./AboutMemberCards/MemberCardRight";

const AboutPage = (propsLeft: any, propsRight: any) => {
  return (
    <Box id="about" className="section text-center">
      <div className="section-title">
        <h2>Über THB IT-Services</h2>
      </div>

      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <MemberCardLeft
            imageLeftAlt="image"
            imageLeftSrc="https://cms.thb-it.de/content/images/2023/03/Torsten.jpg"
            jobTitleLeft="Senior Software-Engineer & Coach"
            nameLeft="Torsten"
            quotationLeft="Seitdem ich mich 2017 selbstständig gemacht habe, erfreut es mich jeden Tag aufs Neue, den positiven Einfluss unserer Tätigkeiten auf unsere Kunden zu beobachten. Dabei basiert unsere Arbeit auf den Grundprinzipien Vertrauen, Kommunikation auf Augenhöhe und kontinuierlicher Weiterentwicklung. Mit Leidenschaft, Spaß und Freude helfen wir unseren Kunden bei der Erreichung ihrer Ziele. Das macht unsere Arbeit aus."
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <MemberCardRight
            imageRightAlt="image"
            imageRightSrc="https://cms.thb-it.de/content/images/2023/02/Michaela--5-von-6-.jpg"
            jobTitleRight="Office Management"
            nameRight="Michaela"
            quotationRight="Teil von THB IT-Services zu sein, ist ein Gewinn. Ich werde kontinuierlich gefordert und gefördert und arbeite flexibel im Homeoffice. Die offene Atmosphäre, großzügige Benefits, Team-Events und flache Hierarchie schätze ich sehr. Hier werden neue Ideen und eigene Stärken unterstützt."
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <MemberCardLeft
            imageLeftAlt="image"
            imageLeftSrc="https://cms.thb-it.de/content/images/2023/03/Philip.jpg"
            jobTitleLeft="Software-Engineer"
            nameLeft="Philip"
            quotationLeft='Ich liebe die Abwechslung, die mit meiner Tätigkeit bei THB IT-Services verbunden ist. Dazu noch gut gelaunte Kollegen und ein Chef, mit dem man über Lösungswege sinnvoll diskutieren kann, runden das Ganze gut ab. Die magischen Worte "Permanentes Home-Office "sprechen für sich selbst ;-)."'
          />
        </Grid>
        {/* <Grid item lg={12} xs={12}>
          <MemberCardLeft
            imageLeftAlt="image"
            imageLeftSrc="https://images.unsplash.com/photo-1697541283989-bbefb5982de9?ixid=M3w5MTMyMXwwfDF8YWxsfDR8fHx8fHwyfHwxNjk4MTU3NjMzfA&ixlib=rb-4.0.3&w=200"
            jobTitleLeft="Software-Engineer"
            nameLeft="Du ?"
            quotationLeft="Du möchtest an spannenden Projekten arbeiten, bist kreativ und ein Teamplayer? Bewirb dich jetzt!"
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default AboutPage;
