import { Button, Grid } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import { useEffect } from "react";
import "./feature.css";
import FeatureController from "./FeatureController";
import FeatureProps from "./FeatureProps";

export const Feature = (props: FeatureProps & CommonProps) => {
  useEffect(() => {
    FeatureController.insertContent(props.slug + "-content", props.content);
    FeatureController.insertIcon(props.slug + "-icon", props.icon);
  }, [props]);

  function scrollToContact() {
    let contact = document.getElementById("contact");
    contact?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Grid
      container
      direction={"column"}
      id={props.slug}
      className={"feature-box " + props.className || ""}
      onClick={() => scrollToContact()}
    >
      <Grid className="feature-box-icon" item>
        <div id={props.slug + "-icon"}></div>
      </Grid>
      <Grid item>
        <h3>{props.title}</h3>
      </Grid>
      <Grid item>
        <hr className="feature-card-seperator" />
      </Grid>
      <Grid item>
        <div id={props.slug + "-content"}></div>
      </Grid>
      <Grid item style={{marginTop: "auto"}}>
        <Button className="feature-button" variant="text">
          Mehr erfahren...
        </Button>
      </Grid>
    </Grid>
  );
};
