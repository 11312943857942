import DataReceivedHandlerRegistration from "src/ui/utils/DataReceivedHandlerRegistration";

const AsyncDataLoader = {
  loadData: <T>(
    loadDataFunction: () => Promise<T>,
    setPropsFunction: React.Dispatch<React.SetStateAction<T | undefined>>
  ) => {
    const onDataReceived = (response: T) => {
      setPropsFunction(() => response);
    };
    DataReceivedHandlerRegistration.registerDataReceivedHandler(
      loadDataFunction(),
      onDataReceived
    );
  },
};
export default AsyncDataLoader;
