import { PostOrPage } from "@tryghost/content-api";
import ServicesProps from "src/ui/Pages/Main/Services/ServicesProps";

const ServicesPropsMapper = {
  map: (postsOrPages: PostOrPage[]): ServicesProps => {
    const domParser: DOMParser = new DOMParser();
    return {
      services: postsOrPages.map((postOrPage) => {
        const document = domParser.parseFromString(
          postOrPage.html!,
          "text/html"
        );
        const icon = document.getElementsByTagName("svg")[0];
        const content = document.getElementsByTagName("p")[0];
        return {
          slug: postOrPage.slug!,
          title: postOrPage.title!,
          content: content.innerHTML,
          icon: icon.outerHTML,
        };
      }),
    };
  },
};

export default ServicesPropsMapper;
