import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import AnforderungsanalysePageProps from "src/ui/Pages/Services/Anforderungsanalyse/AnforderungsanalysePageProps";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";

const SERVICE_ANFORDERUNGSANALYSE_CONTENT_IDENTIFIER =
  "service-anforderungsanalyse";

const AnforderungsanalysePageInteractions = {
  loadAnforderungsanalysePage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): AnforderungsanalysePageProps => {
    return {
      loadAnforderungsanalyseContentFunction: () =>
        loadAnforderungsanalyseContent(repository, cacheEntityResolver),
    };
  },
};

export default AnforderungsanalysePageInteractions;

async function loadAnforderungsanalyseContent(
  repository: typeof GhostRepository,
  cacheEntityResolver: CacheEntityResolver
): Promise<HtmlPanelProps> {
  const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
    () =>
      repository.findSinglePostOrPageByIdentifierAsHtml(
        SERVICE_ANFORDERUNGSANALYSE_CONTENT_IDENTIFIER
      ),
    SERVICE_ANFORDERUNGSANALYSE_CONTENT_IDENTIFIER
  );

  return HtmlPanelPropsMapper.map(postsOrPage);
}
