import { useEffect, useState } from "react";
import HtmlPanel from "src/ui/components/HtmlPanel/HtmlPanel";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import SystemUndSoftwareArchitekturPageProps from "./SystemUndSoftwareArchitekturPageProps";

const SystemUndSoftwareArchitekturPage = (
  props: SystemUndSoftwareArchitekturPageProps
) => {
  const [
    systemUndSoftwarearchitekturContent,
    setSystemUndSoftwarearchitekturContent,
  ] = useState<HtmlPanelProps>();

  useEffect(() => {
    props
      .loadSystemUndSoftwareArchitekturContentFunction()
      .then(setSystemUndSoftwarearchitekturContent);
  }, [props]);

  return (
    <HtmlPanel id="architecture" {...systemUndSoftwarearchitekturContent} />
  );
};

export default SystemUndSoftwareArchitekturPage;
