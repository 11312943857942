import { useEffect, useState } from "react";
import HtmlPanel from "src/ui/components/HtmlPanel/HtmlPanel";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import TechnologieauswahlUndEvaluationPageProps from "./TechnologieauswahlUndEvaluationPageProps";

const TechnologieauswahlUndEvaluationPage = (
  props: TechnologieauswahlUndEvaluationPageProps
) => {
  const [
    technologieauswahlUndEvaluationContent,
    setTechnologieauswahlUndEvaluationContent,
  ] = useState<HtmlPanelProps>();

  useEffect(() => {
    props
      .loadTechnologieauswahlUndEvaluationContentFunction()
      .then(setTechnologieauswahlUndEvaluationContent);
  }, [props]);

  return (
    <HtmlPanel id="technology" {...technologieauswahlUndEvaluationContent} />
  );
};

export default TechnologieauswahlUndEvaluationPage;
