import { SettingsResponse } from "@tryghost/content-api";
import PageSettings from "src/integration/app/PageSettings";

const PageSettingsMapper = {
  map: async (settingsResponse: SettingsResponse): Promise<PageSettings> => {
    return {
      title: settingsResponse.title!,
      description: settingsResponse.description!,
      logoUrl: settingsResponse.logo!,
      iconUrl: settingsResponse.icon!,
      coverImageUrl: settingsResponse.cover_image!,
    };
  },
};
export default PageSettingsMapper;
