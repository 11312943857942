import { useEffect, useState } from "react";
import HtmlPanel from "src/ui/components/HtmlPanel/HtmlPanel";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import CoachingPageProps from "./CoachingPageProps";

const CoachingPage = (props: CoachingPageProps) => {
  const [coachingContent, setCoachingContent] = useState<HtmlPanelProps>();

  useEffect(() => {
    props.loadCoachingContentFunction().then(setCoachingContent);
  }, [props]);

  return <HtmlPanel id="coaching" {...coachingContent} />;
};

export default CoachingPage;
