import { useEffect, useState } from "react";
import HtmlPanel from "src/ui/components/HtmlPanel/HtmlPanel";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import AnforderungsanalysePageProps from "./AnforderungsanalysePageProps";

const AnforderungsanalysePage = (props: AnforderungsanalysePageProps) => {
  const [anforderungsanalyseContent, setAnforderungsanalyseContent] =
    useState<HtmlPanelProps>();

  useEffect(() => {
    props
      .loadAnforderungsanalyseContentFunction()
      .then(setAnforderungsanalyseContent);
  }, [props]);

  return <HtmlPanel id="anforderungsanalyse" {...anforderungsanalyseContent} />;
};

export default AnforderungsanalysePage;
