import PageSettings from "src/integration/app/PageSettings";
import NavigationProps from "src/ui/app/Layout/Navigation/NavigationProps";

const NavigationPropsMapper = {
  map: (pageSettings: PageSettings): NavigationProps => {
    return {
      logoUrl: pageSettings.logoUrl,
      title: pageSettings.title,
    };
  },
};
export default NavigationPropsMapper;
