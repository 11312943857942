import NavigationIcon from "@mui/icons-material/Navigation";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { useState } from "react";
import "./floatingActionButton.css";

const ScrollButton = () => {

  const [visible, setVisible] = useState(false);
  
  const toggleVisible = () => {
    const scrollY = window.scrollY;
    if (scrollY < 300) {
      setVisible(false);
    } else if (scrollY >= 300) {
      setVisible(true);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.onscroll = toggleVisible;

  return (
    <div className={"fab_container"}>
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <a href="#page-top" className="fab_link">
          <Fab
            className="floating_action_button"
            size="large"
            variant="extended"
            style={{ display: visible ? "block" : "none" }}
            onClick={scrollToTop}
          >
            <NavigationIcon
              sx={{ mr: 0 }}
              style={{ color: "white", transform: "scale(1.5)" }}
            />
          </Fab>
        </a>
      </Box>
    </div>
  );
};

export default ScrollButton;
