import { PostOrPage } from "@tryghost/content-api";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";

const HtmlPanelPropsMapper = {
  map: (postOrPage: PostOrPage): HtmlPanelProps => {
    return {
      html: postOrPage.html!,
      title: postOrPage.title!,
    };
  },
};
export default HtmlPanelPropsMapper;
