import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import TrainingPageProps from "src/ui/Pages/Services/Training/TrainingPageProps";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";

const SERVICE_TRAINING_CONTENT_IDENTIFIER = "service-training";

const TrainingPageInteractions = {
  loadTrainingPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): TrainingPageProps => {
    return {
      loadTrainingContentFunction: () =>
        loadTrainingContent(repository, cacheEntityResolver),
    };
  },
};

export default TrainingPageInteractions;

async function loadTrainingContent(
  repository: typeof GhostRepository,
  cacheEntityResolver: CacheEntityResolver
): Promise<HtmlPanelProps> {
  const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
    () =>
      repository.findSinglePostOrPageByIdentifierAsHtml(
        SERVICE_TRAINING_CONTENT_IDENTIFIER
      ),
    SERVICE_TRAINING_CONTENT_IDENTIFIER
  );

  return HtmlPanelPropsMapper.map(postsOrPage);
}
