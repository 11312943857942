import GoogleMapsLinkProps from "./GoogleMapsLinkProps";

const GoogleMapsLink = (props: GoogleMapsLinkProps) => {
  return (
    <a
      href={props.url}
      target="_blank"
      rel="noreferrer"
      className="google-maps-link"
    >
      {props.displayText}
    </a>
  );
};

export default GoogleMapsLink;
