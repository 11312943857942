import GoogleMapsLink from "src/ui/components/GoogleMapsLink/GoogleMaplsLink";
import FeaturedPage from "./FeaturedPage/FeaturedPage";
import FooterProps from "./FooterProps";
import "./footer.css";

const Footer = (props: FooterProps) => {
  return (
    <>
      <footer id="footer" className="container-fluid section text-center">
        <div className="row footer-above">
          <div className="footer-col col-xs-6">
            <ul>
              <li className="footer-title">
                <h4>{props.name}</h4>
              </li>
              {props.owner && <li>{props.owner}</li>}
              <li>
                <GoogleMapsLink
                  url={props.googleMapsLink}
                  displayText={props.address}
                />
              </li>
              <li>
                <a href={"mailto:" + props.email}>
                  <i className="fa fa-envelope"></i> {props.email}
                </a>
              </li>
              <li>
                <a href={"tel:" + props.phone}>
                  <i className="fa fa-phone"></i> {props.phone}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-col col-xs-6">
            <ul>
              <li className="footer-title">
                <h4>Rechtliches</h4>
              </li>
              <li>
                <a href="/privacy-policy">Datenschutzerkl&auml;rung</a>
              </li>
              <li>
                <a href="/legal-notes">Datenschutzhinweise</a>
              </li>
              <li>
                <a href="/impressum">Impressum</a>
              </li>
            </ul>
          </div>
          {props.featuredPage && (
            <FeaturedPage
              imageUrl={props.featuredPage.imageUrl}
              targetUrl={props.featuredPage.targetUrl}
              text={props.featuredPage.text}
            />
          )}
        </div>
      </footer>
      <div id="copyright" className="text-center">
        Copyright &copy; THB IT-Services 2017-{new Date().getFullYear()}
      </div>
    </>
  );
};
export default Footer;
