import { PostOrPage } from "@tryghost/content-api";
import TestimonialsProps from "src/ui/Pages/Main/Testimonials/TestimonialsProps";

const TestimonialsPropsMapper = {
  map: (postsOrPages: PostOrPage[]): TestimonialsProps => {
    return {
      testimonials: postsOrPages.map((testimonialPost) => {
        return {
          imageUrl: testimonialPost.feature_image!,
          content: testimonialPost.plaintext!,
          source: testimonialPost.title!,
        };
      }),
    };
  },
};

export default TestimonialsPropsMapper;
