import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import WorkIcon from "@mui/icons-material/Work";
import { Chip, Collapse } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useEffect, useRef, useState } from "react";
import ProjectsController from "../ProjectsController";
import "./project.css";
import ProjectDetails from "./ProjectDetails";
import ProjectProps from "./ProjectProps";

const Project = (
  props: ProjectProps & {
    onChange?: (
      index: number,
      showDetails: boolean,
      containerRef: React.RefObject<HTMLDivElement>
    ) => void;
  }
) => {
  const [detailVisible, setDetailVisible] = useState(false);
  const containerRef: React.RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);

  useEffect(() => {
    ProjectsController.setBackgroundImage(containerRef, props.imageUrl);
  }, [props.imageUrl]);

  function containerClicked() {
    setDetailVisible(!detailVisible);
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
    if (props.onChange) {
      props.onChange(props.index, detailVisible, containerRef);
    }
  }

  let currentKey = 0;
  return (
    <>
      <div>
        <Paper
          className="project-card"
          ref={containerRef}
          onClick={() => containerClicked()}
        >
          <h3>{props.title}</h3>
          <h4>{props.duration}</h4>
          {props.industrySectors.map((sector, index) => {
            return (
              <Chip
                key={currentKey++}
                avatar={<CorporateFareIcon className="chip-avatar" />}
                label={sector}
                className="chip"
              />
            );
          })}
          {props.roles.map((role, index) => {
            return (
              <Chip
                key={currentKey++}
                avatar={<WorkIcon className="chip-avatar" />}
                label={role}
                className="chip"
              />
            );
          })}
          <Collapse in={detailVisible} appear={detailVisible} unmountOnExit>
            <ProjectDetails {...props} />
          </Collapse>
        </Paper>
      </div>
    </>
  );
};
export default Project;
