import { useEffect, useState } from "react";
import HtmlPanel from "src/ui/components/HtmlPanel/HtmlPanel";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import LegalNotesPageProps from "./LegalNotesPageProps";

const LegalNotesPage = (props: LegalNotesPageProps) => {
  const [legalNotesContent, setLegalNotesContent] = useState<HtmlPanelProps>();

  useEffect(() => {
    props.loadLegalNotesContentFunction().then(setLegalNotesContent);
  }, [props]);

  return <HtmlPanel id="legal-notes" {...legalNotesContent} />;
};

export default LegalNotesPage;
