import { PostOrPage } from "@tryghost/content-api";
import TeamProps from "src/ui/Pages/Main/Team/TeamProps";

const TeamPropsMapper = {
  map: (postsOrPages: PostOrPage[]): TeamProps => {
    return {
      members: postsOrPages.map((teamPost) => {
        return {
          title: teamPost.title!,
          content: teamPost.html!,
          imageUrl: teamPost.feature_image!,
        };
      }),
    };
  },
};

export default TeamPropsMapper;
