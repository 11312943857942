import { Grid, Link } from "@mui/material";
import { useEffect } from "react";
import "./service.css";
import ServiceController from "./ServiceController";
import ServiceProps from "./ServiceProps";

export const Service = (props: ServiceProps) => {
  useEffect(() => {
    ServiceController.insertContent(props.slug + "-content", props.content);
    ServiceController.insertIcon(props.slug + "-icon", props.icon);
  }, [props]);

  function scrollToContact() {
    let contact = document.getElementById("contact");
    contact?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="service-item"
        onClick={() => scrollToContact()}
      >
        <Grid item id={props.slug + "-icon"}></Grid>
        <Grid item className="service-desc">
          <h4>{props.title}</h4>
          <p id={props.slug + "-content"}>{props.content}</p>
          <p>
            <Link>Mehr erfahren...</Link>
          </p>
        </Grid>
      </Grid>
    </>
  );
};
