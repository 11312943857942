import { CacheEntityResolver } from "@thb-it-oldenburg/cache-module";
import { PostOrPage } from "@tryghost/content-api";
import GhostRepository from "src/infrastructure/ghost/GhostRepository";
import HtmlPanelProps from "src/ui/components/HtmlPanel/HtmlPanelProps";
import ImpressumPageProps from "src/ui/Pages/legal/Impressum/ImpressumPageProps";
import HtmlPanelPropsMapper from "../common/HtmlPanelPropsMapper";

const IMPRESSUM_IDENTIFIER = "impressum";

const ImpressumPageInteractions = {
  loadImpressumPage: (
    repository: typeof GhostRepository,
    cacheEntityResolver: CacheEntityResolver
  ): ImpressumPageProps => {
    return {
      loadImpressumContentFunction: () =>
        loadImpressumContent(repository, cacheEntityResolver),
    };
  },
};

export default ImpressumPageInteractions;

async function loadImpressumContent(
  repository: typeof GhostRepository,
  cacheEntityResolver: CacheEntityResolver
): Promise<HtmlPanelProps> {
  const postsOrPage: PostOrPage = await cacheEntityResolver.resolve(
    () =>
      repository.findSinglePostOrPageByIdentifierAsHtml(IMPRESSUM_IDENTIFIER),
    IMPRESSUM_IDENTIFIER
  );

  return HtmlPanelPropsMapper.map(postsOrPage);
}
