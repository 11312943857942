import { useEffect, useState } from "react";
import useScrollToLocation from "src/ui/hooks/useScrollToLocation";
import AsyncDataLoader from "../../utils/AsyncDataLoader";
import AboutPage from "./About/AboutPage";
import { Contact } from "./Contact/Contact";
import ContactProps from "./Contact/ContactProps";
import { Features } from "./Features/Features";
import FeaturesProps from "./Features/FeaturesProps";
import MainPageProps from "./MainPageProps";
import Projects from "./Projects/Projects";
import ProjectsProps from "./Projects/ProjectsProps";
import { Services } from "./Services/Services";
import ServicesProps from "./Services/ServicesProps";
import { Team } from "./Team/Team";
import TeamProps from "./Team/TeamProps";
import Techstack from "./Techstack/Techstack";
import Testimonials from "./Testimonials/Testimonials";
import TestimonialsProps from "./Testimonials/TestimonialsProps";

const MainPage = (props: MainPageProps) => {
  useScrollToLocation();
  const [featuresProps, setFeaturesProps] = useState<FeaturesProps>();
  const [servicesProps, setServicesProps] = useState<ServicesProps>();
  const [teamProps, setTeamProps] = useState<TeamProps>();
  const [contactProps, setContactProps] = useState<ContactProps>();
  const [testimonialsProps, setTestimonialsProps] =
    useState<TestimonialsProps>();
  //const [aboutProps, setAboutProps] = useState<HtmlPanelProps>();
  const [projectsProps, setProjectsProps] = useState<ProjectsProps>();

  useEffect(() => {
    AsyncDataLoader.loadData(props.loadFeaturesFunction, setFeaturesProps);
    AsyncDataLoader.loadData(props.loadServicesFunction, setServicesProps);
    AsyncDataLoader.loadData(props.loadTeamFunction, setTeamProps);
    AsyncDataLoader.loadData(props.loadContactFunction, setContactProps);
    AsyncDataLoader.loadData(
      props.loadTestimonialsFunction,
      setTestimonialsProps
    );
    //AsyncDataLoader.loadData(props.loadAboutFunction, setAboutProps);
    AsyncDataLoader.loadData(props.loadProjectsFunction, setProjectsProps);
  }, [props]);
  //<About {...aboutProps} />

  return (
    <div>
      <Features {...featuresProps} />
      <AboutPage />
      <Services {...servicesProps} />
      <Techstack />
      <Projects {...projectsProps} />
      <Testimonials {...testimonialsProps} />

      <Team {...teamProps} />

      <Contact {...contactProps} />
    </div>
  );
};
export default MainPage;
