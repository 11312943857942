const BackgroundImageReplacer = {
  setBackgroundImage: (
    ref: React.RefObject<HTMLDivElement>,
    cssVariableName: string,
    imageUrl?: string
  ): void => {
    if (ref.current && imageUrl) {
      const style = ref.current.style;
      style.setProperty("--" + cssVariableName, 'url("' + imageUrl + '")');
    }
  },
};

export default BackgroundImageReplacer;
